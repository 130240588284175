import fetchVehicleMonitoring from './api/fetchVehicleMonitoring'
import useVehicleIDQueryParam from './hooks/useVehicleIDQueryParam'
import HomePage from './pages/Home'
import VehicleJourneyPage from './pages/VehicleJourney'

function App() {
  const { vehicleID, setVehicleIDQueryParam } = useVehicleIDQueryParam()

  return (
    <div>
      {/* Homepage */}
      {!vehicleID && <HomePage />}
      {/* Journey Page */}
      {vehicleID && <VehicleJourneyPage vehicleID={vehicleID} />}

      {/* Debug button for finding a random vehicle */}
      {process.env.NODE_ENV === 'development' && (
        <button
          onClick={async () => {
            const allVehicles = await fetchVehicleMonitoring()
            // Get all vehicles which have onward calls
            const vehicleActivities =
              allVehicles.Siri.ServiceDelivery?.VehicleMonitoringDelivery
                ?.VehicleActivity

            const randomNumber = Math.floor(
              Math.random() * vehicleActivities.length,
            )
            // Pick random vehicle
            const randomVehicleID = vehicleActivities
              .filter(
                (activity) =>
                  activity.MonitoredVehicleJourney.OnwardCalls?.OnwardCall,
              )
              .at(randomNumber)?.MonitoredVehicleJourney.VehicleRef

            if (randomVehicleID)
              setVehicleIDQueryParam(parseInt(randomVehicleID))
          }}
        >
          Random vehicle
        </button>
      )}
    </div>
  )
}

export default App
