import { useEffect, useState } from 'react'
import fetchVehicleMonitoring, {
  getVehicleJourney,
} from '../api/fetchVehicleMonitoring'
import OnwardCallList from '../components/OnwardCallList'

const VehicleJourneyPage = ({ vehicleID }: { vehicleID?: number }) => {
  const [counter, setCounter] = useState(0)
  const [loading, setLoading] = useState(true)
  const [vehicleJourney, setVehicleJourney] =
    useState<VehicleActivity['MonitoredVehicleJourney']>()
  const [stops, setStops] = useState<Array<OnwardCall | MonitoredCall>>([])

  // Count down timer
  useEffect(() => {
    if (vehicleJourney) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    }
  }, [counter, vehicleJourney])

  useEffect(() => {
    const fetchAndSetVehicleActivity = async () => {
      const vehicleMonitoringRes = await fetchVehicleMonitoring(vehicleID)
      const vehicleJourney = getVehicleJourney(vehicleMonitoringRes)

      // Save the vehicle journey and stops if there are values
      if (vehicleJourney) {
        setVehicleJourney(vehicleJourney)

        let newJourneyStops: Array<OnwardCall | MonitoredCall> = []
        const monitoredCall = vehicleJourney?.MonitoredCall
        const onwardCalls = vehicleJourney?.OnwardCalls?.OnwardCall

        if (monitoredCall) {
          newJourneyStops = [monitoredCall]
        }
        if (onwardCalls) {
          newJourneyStops = [...newJourneyStops, ...onwardCalls]
        }

        // Only update stops if the new request has stops, otherwise use old request
        if (newJourneyStops.length > 0) {
          setStops(newJourneyStops)
        }

        setLoading(false)
        setCounter(60)
      }
    }

    if (counter === 0) {
      setLoading(true)
      fetchAndSetVehicleActivity()
    }
  }, [vehicleID, counter, stops])

  return (
    <div style={{ padding: '1em' }}>
      <div>
        <h1>Vehicle #{vehicleID}</h1>
        {vehicleJourney?.LineRef && vehicleJourney?.DestinationName && (
          <h3>
            {vehicleJourney.LineRef} {vehicleJourney.PublishedLineName} to{' '}
            {vehicleJourney.DestinationName}
          </h3>
        )}
      </div>

      <div>
        <p style={{ textAlign: 'right' }}>
          {loading ? 'Loading...' : `Next refresh in ${counter}s`}
        </p>
        {stops.length > 0 && <OnwardCallList onwardCalls={stops} />}
        {stops.length === 0 && !loading && (
          <h3>Tracking is unavailable. Refresh the page to try again.</h3>
        )}
      </div>
    </div>
  )
}

export default VehicleJourneyPage
