import VehicleIDForm from '../components/VehicleIDForm'

const HomePage = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        height: '85vh',
        flexDirection: 'column',
      }}
    >
      <div style={{ padding: '1em' }}>
        <span style={{ fontSize: '5.5em' }}>🌁</span>
        <h1 style={{ margin: 0 }}>MUNI On the Go</h1>
        <p style={{ marginTop: '0.5em' }}>
          See the live schedule of the SF MUNI bus or train you're currently
          riding.
        </p>
      </div>
      {/* Vertically centered */}
      <div
        style={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '1em',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', maxWidth: '30em' }}>
          <VehicleIDForm />
          <p style={{ color: 'grey' }}>
            <b>Hint: </b>The vehicle ID number is a 4 digit number found inside
            and outside of the bus or train. It's usually prominently visible at
            the back, near the door, or near the driver's cab.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomePage
