const useVehicleIDQueryParam = () => {
  const setVehicleIDQueryParam = (vehicleID: number): void => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('vehicleID', vehicleID.toString())
    window.location.search = searchParams.toString()
  }

  let vehicleID: number | undefined
  const vehicleIDQueryParam = new URLSearchParams(window.location.search).get(
    'vehicleID',
  )
  // Convert query param to number or leave as undefined
  if (vehicleIDQueryParam) {
    const vehicleIDNumber = parseInt(vehicleIDQueryParam)
    if (!isNaN(vehicleIDNumber)) {
      vehicleID = vehicleIDNumber
    }
  }

  return { vehicleID, setVehicleIDQueryParam }
}

export default useVehicleIDQueryParam
